import crypto from 'crypto'
import { DateTime } from 'luxon'
import { BITCOIN_START } from './bitcoin'
import { csv } from 'd3-fetch'
import { Workbook } from 'exceljs'
import { DSVRowArray, format } from 'd3'
import { filter } from './filters'
import { stringToNumber } from './bignum'

export interface CustomFile {
  name: string
  size: number
  type: string
  fileExtension: string
  url: string | ArrayBuffer | null
  isImage: boolean
  isUploaded: boolean
}

export function titleCase(word: string): string {
  const split = word.split('')
  split[0] = split[0].toUpperCase()
  return split.join('')
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopy(text)
  } else {
    navigator.clipboard.writeText(text).then(
      function () {
        // console.log('Async: Copying to clipboard was successful!')
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }
}

export function fallbackCopy(text: string) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  let successful = false
  try {
    successful = document.execCommand('copy')
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }
  if (!successful) {
    console.error('Fallback: Oops, unable to copy')
  }
  document.body.removeChild(textArea)
}

export function cutMiddle(value: string, length: number = 6): string {
  if (!value) return ''
  const maxLength = length * 2 + 3
  return value.length > maxLength ? `${value.substring(0, length)}...${value.substring(value.length - length)}` : value
}

export function cutEnd(value: string, length: number = 7): string {
  if (!value) return ''
  const maxLength = length + 3
  return value.length > maxLength ? `${value.substring(0, length)}...` : value
}

export function hex2ascii(hex: string): string {
  hex = hex.toString().replace(/\s+/gi, '')
  const stack: string[] = []

  for (let i = 0; i < hex.length; i += 2) {
    const code = parseInt(hex.substr(i, 2), 16)
    if (!isNaN(code) && code !== 0) {
      stack.push(String.fromCharCode(code))
    }
  }

  return stack.join('')
}

export function jsonToCSV(
  data: any[],
  excludeKeys: string[] = [],
  includeKeys: string[] = [],
  delimiter: string = ','
): string {
  const keys = Object.keys(data[0])
  // remove columns
  for (const key of excludeKeys) {
    keys.splice(keys.indexOf(key), 1)
  }
  // add columns
  for (const key of includeKeys) {
    if (!keys.includes(key)) {
      keys.push(key)
    }
  }
  const csv: string[] = []
  // header
  csv.push(keys.join(delimiter))
  // rows
  for (const d of data) {
    const line: string[] = []
    for (const key of keys) {
      const value = d[key]
      if (Array.isArray(value) && value.length > 1) {
        line.push(`"${value.join(delimiter)}"`)
      } else {
        line.push(value)
      }
    }
    csv.push(line.join(delimiter))
  }
  return csv.join('\n')
}

export function downloadCSV(data: any, filename: string, excludeKeys: string[] = [], includeKeys: string[] = []) {
  const csv = jsonToCSV(data, excludeKeys, includeKeys)
  download(csv, filename, 'text/csv')
}

export function download(data: string | Blob, filename: string, type: string) {
  const blob = new Blob([data], { type })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.click()
  URL.revokeObjectURL(link.href)
}

export function timeToMilliseconds(time: number): number {
  if (time.toString().length <= 9) {
    //cryptotime
    return (time + BITCOIN_START) * 1000
  }
  if (time.toString().length === 10) {
    //epoch
    return time * 1000
  }
  // else already in milliseconds
  return time
}

export function formatDate(epoch: number, ms: boolean, utc: boolean = true, shortness?: number): string {
  if (!ms) {
    epoch = epoch * 1000
  }
  const dtOptions = utc ? { zone: 'UTC' } : undefined
  const dt = DateTime.fromMillis(epoch, dtOptions)
  let formatted
  switch (shortness) {
    case 0:
      formatted = `${dt.toFormat('L/d/yy HH:mm:ss ZZZZ')}`
      if (!utc) return formatted.replace(/E(S|D)T/, 'ET')
      return formatted
    case 2:
      return `${dt.toFormat('L/d/yy')}`
    case 3:
      return `${dt.toFormat('L/yy')}`
    case 1:
    default:
      formatted = `${dt.toFormat('L/d/yy HH:mm ZZZZ')}`
      if (!utc) return formatted.replace(/E(S|D)T/, 'ET')
      return formatted
  }
}

export function stringDateToCryptoTime(strDt: string): number {
  const dt = DateTime.fromFormat(strDt, 'yyyy-L-d')
  const seconds = dt.toMillis() / 1000
  return seconds - BITCOIN_START
}

export function chunkArray<T>(data: T[], chunkSize: number): T[][] {
  const chunkCount = Math.ceil(data.length / chunkSize)
  const chunks: any[][] = []
  for (let i = 0; i < chunkCount; i++) {
    const start = i * chunkSize
    const end = (i + 1) * chunkSize
    const chunk = data.slice(start, end)
    chunks.push(chunk)
  }
  return chunks
}

export interface StringNumberMap {
  [key: string]: string | number
}
export interface NestedStringNumberMap {
  [key: string]: string | number | StringNumberMap
}
export interface NestedSearchKeys {
  [key: string]: string[]
}

export function customSearch<T, K extends NestedStringNumberMap & T>(
  items: K[],
  search: string,
  keys: Array<string | NestedSearchKeys>,
  type: string
): T[] {
  if (search == null || search === '') {
    return items
  }
  search = search.toLowerCase()
  if (search.startsWith('#')) {
    const iStr = search.replace('#', '')
    const i = parseInt(iStr, 10)
    if (i.toString() !== iStr) {
      console.log(`incorrect ${type} index search format: ${search}`)
      return items
    }
    return filter(items, (item) => {
      if (type === 'inputs') {
        return item.vout === i
      }
      // else type === outputs
      return item.n === i
    })
  }
  return filter(items, (item) => {
    for (const key of keys) {
      if (typeof key === 'string') {
        const value = item[key].toString().toLowerCase()
        if (value.includes(search)) {
          return true
        }
      }
      if (typeof key !== 'string') {
        const iKey = Object.keys(key)[0]
        const nKeys = key[iKey]
        const nItem = item[iKey] as StringNumberMap
        for (const nKey of nKeys) {
          const value = nItem[nKey].toString().toLowerCase()
          if (value.includes(search)) {
            return true
          }
        }
      }
    }
    return false
  })
}

interface BasicStringMap {
  [key: string]: string | number
}

export function snakeToCamelCase<R>(snake: any): R {
  const camel: BasicStringMap = {}
  Object.keys(snake).forEach((key) => {
    const camelCaseKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
    camel[camelCaseKey] = snake[key]
  })
  return camel as any
}

export function prettyRoundedNumber(num: number | string, decimals?: number, exactDecimals?: boolean) {
  if (decimals != null)
    return format(`,.${decimals}${exactDecimals ? '' : '~'}f`)(stringToNumber(num))
  return format(',~f')(stringToNumber(num))
}

export function formatFiat(amount: number, decimals: number = 2, locale: string = 'en-US') {
  const trimmed = parseFloat(amount.toFixed(decimals))
  return trimmed.toLocaleString(locale)
}

// encoding

/**
 * ASCII to Unicode (decode Base64 to original data)
 */
export function atou(b64: string): string {
  return decodeURIComponent(escape(atob(b64)))
}

/**
 * Unicode to ASCII (encode data to Base64)
 */
export function utoa(data: string): string {
  return btoa(unescape(encodeURIComponent(data)))
}

export function md5(data: Object | string): string {
  const stringValue = typeof data === 'string' ? data : JSON.stringify(data)
  return crypto.createHash('md5').update(stringValue).digest('hex')
}

// server events

export interface ServerEvent {
  success: boolean
  requestedUrl: string
  clientId: string
  type: string
  data: any
  message?: string
}

export function parseServerEvent(event: string): ServerEvent {
  try {
    const parsed: ServerEvent = JSON.parse(event)
    return parsed
  } catch (e) {
    return {
      success: false,
      requestedUrl: '',
      clientId: '',
      type: '',
      data: null,
      message: `${e}`
    }
  }
}

// validation methods

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const invalidEmailMessage = 'Invalid email address'

// general validation rules
export function password(value: string): true | string {
  const pattern = /^[0-9a-z\-_&@]{8,}$/i
  return pattern.test(value) || 'Only: a-z, 0-9, - _ & @. 8 characters minimum'
}

export function alphaCaseInsensitive(value: string): true | string {
  const pattern = /[a-z]{1,}/i
  return pattern.test(value) || 'Must have at least one letter'
}

export function number(value: string): true | string {
  const pattern = /[0-9]{1,}/
  return pattern.test(value) || 'Must have at least one number'
}

export function email(value: string): true | string {
  const pattern = emailRegex

  return pattern.test(value) || invalidEmailMessage
}

export function emailOptional(value: string): true | string {
  const pattern = emailRegex
  return pattern.test(value) || value.length === 0 || invalidEmailMessage
}

export function alphaNumericMin(value: string, min: number = 4): true | string {
  const pattern = /^[a-z0-9]+$/i
  return (pattern.test(value) && value.length >= min) || `Invalid username. a-z, 0-9, ${min} characters minimum`
}

export function alphaNumericOptional(value: string): true | string {
  const pattern = /^[a-z0-9]{0,}$/i
  return pattern.test(value) || `Invalid username. a-z, 0-9`
}

export function required(value: string): true | string {
  return !!value || 'Required'
}

export function sortReports(reports: any[], field: string, condition?: FilterCondition): any[] {
  const filtered = condition ? filterReports(reports, condition) : reports
  return filtered.sort((a, b) => {
    const valueA = getValueFromField(a, field)
    const valueB = getValueFromField(b, field)

    if (valueA < valueB) {
      return -1
    } else if (valueA > valueB) {
      return 1
    } else {
      return 0
    }
  })
}

export function getValueFromField(report: any, field: string): any {
  const fields = field.split('.')
  let value = report
  let temp = value

  for (const f of fields) {
    temp = Array.isArray(value[f]) ? value[f][0] : value[f]
    if (temp === undefined) {
      break
    }
    value = temp
  }

  return typeof value === 'number' ? value : -1
}

type FilterCondition = (report: any) => boolean

function filterReports(reports: any[], condition: FilterCondition): any[] {
  return reports.map((report) => ({
    ...report,
    flows: report.flows.filter((flow: any) => condition(flow))
  }))
}

export async function getCsvOrExcelData(files: CustomFile[]) {
  const { name, url, fileExtension } = files[0]
  if (typeof url !== 'string') {
    console.warn('data url is not formatted correctly')
    return undefined
  }
  let dsvData: DSVRowArray<string>[] = []
  switch (fileExtension) {
    case 'csv':
      dsvData.push(await csv(url))
      return { data: dsvData, name }
    case 'xlsx':
      const split = url.split(',')
      if (split.length < 2) {
        console.warn('data url is not formatted correctly')
        return undefined
      }
      const data = split[1]
      const excelBuffer = Buffer.from(data, 'base64')
      const workbook = new Workbook()
      const excelData = await workbook.xlsx.load(excelBuffer)
      await Promise.all(excelData.worksheets.map(async sheet => {
        const csvBuffer = await excelData.csv.writeBuffer({ sheetId: sheet.id }) as Buffer // need to cast because the typing isn't set up correctly
        const csvUrl = bufferToUrl(csvBuffer, 'utf8', 'text/csv')
        dsvData.push(await csv(csvUrl))
      }))
      return { data: dsvData, name }
  }
  return undefined
}

export function bufferToUrl(buffer: Buffer, encoding: BufferEncoding, mime: string): string {
  const data = buffer.toString(encoding)
  return `data:${mime};${encoding},${buffer}`
}

export function formatNetwork(network: string) {
  network = network.toLowerCase()
  switch (network) {
    case 'btc':
      return 'bitcoin'
    case 'eth':
      return 'ethereum'
    default:
      return network
  }
}

export const NETWORKS_SET = new Set([
  // this set should eventually include all known networks/currencies, both full names and shortened
  'btc',
  'bitcoin',
  'eth',
  'ethereum',
  'xmr'
])

export async function renderHtmlToCanvas(
  html: string,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number
): Promise<any> {
  const xml = htmlToXml(html).replace(/\#/g, '%23')
  const data = 
    `data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">` +
    `<foreignObject width="100%" height="100%">${xml}</foreignObject></svg>`

  const img = new Image()
  return new Promise((resolve, reject) => {
    img.onload = () => {
      ctx.drawImage(img, x, y)
      return resolve(true)
    }
    img.src = data
  })
}

function htmlToXml(html: string) {
  const doc = document.implementation.createHTMLDocument('')
  doc.write(html)

  // You must manually set the xmlns if you intend to immediately serialize     
  // the HTML document to a string as opposed to appending it to a
  // <foreignObject> in the DOM
  doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI!)

  // Get well-formed markup
  return (new XMLSerializer).serializeToString(doc.body)
}
