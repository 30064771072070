
import { FormComponent } from '@/components/Dialog.vue'
import { Component } from 'vue-property-decorator'

interface Hotkey {
  buttons: string[]
  action: string
}

@Component
export default class Legend extends FormComponent { // extend FormComponent to inherit empty doAction and avoid error
  public hotkeys: Hotkey[] = [
    {
      buttons: [
        'click'
      ],
      action: 'select node or link'
    },
    {
      buttons: [
        'click',
        'drag'
      ],
      action: 'move node(s) and freeze in place'
    },
    {
      buttons: [
        'shift',
        'click'
      ],
      action: 'add/remove node or link to/from selection'
    },
    {
      buttons: [
        'shift',
        'click',
        'drag'
      ],
      action: 'select node group'
    },
    {
      buttons: [
        'delete/backspace'
      ],
      action: 'delete selected node(s) and/or link(s)'
    },
    {
      buttons: [
        'escape'
      ],
      action: 'unselect node(s) and/or link(s)'
    },
    {
      buttons: [
        'control',
        'shift'
      ],
      action: 'show deleted nodes and links'
    },
    {
      buttons: [
        'control',
        'shift',
        'click'
      ],
      action: 'undelete node or link'
    },
    {
      buttons: [
        'control',
        'click'
      ],
      action: 'unfreeze node'
    },
    {
      buttons: [
        'right click'
      ],
      action: 'rename node'
    },
    
  ]

  public formatButtons(buttons: string[]) {
    return buttons.join(' + ')
  }
}
